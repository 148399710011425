<script>
import carsInfo from "./carsInfo";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export default {
  name: "CarsIntroduction-detail",
  data() {
    return {
      carsInfo: carsInfo,
      currentCarInfo: null,
      activeItem: 1,
      userAgent: "",
      currentDCBatteryPercentage: 10,
      currentDCTime: 0,
      currentDCTimeFinal: "00:00",
      currentDCRangePlus: 0,
      currentACBatteryPercentage: 10,
      currentACTime: 0,
      currentACTimeFinal: "00:00",
      currentACRangePlus: 0,
      isPageControllerShow: false,
      initBatteryAnimate: false,
    };
  },
  beforeDestroy() {
    // 在 beforeDestroy 鉤子中移除捲動事件監聽器
    this.checkIsPageControllerShow("destroy");
    this.checkIsBatteryAnimateInit("destroy");
  },
  mounted() {
    this.checkDeviceOs();
    const pathCar = this.$route.params.car;
    this.carsInfo.map((car) => {
      if (car.id === pathCar) {
        this.currentCarInfo = car;
      }
    });
    this.$nextTick(() => {
      setTimeout(() => {
        this.scrollToTop();
      }, 0);
    });
    // this.initDCPercentage();
    // this.initACPercentage();
    this.checkIsPageControllerShow();
    this.checkIsBatteryAnimateInit();
  },
  methods: {
    animateDCValue(target, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        if (target === "battery") {
          this.currentDCBatteryPercentage = Math.floor(
            progress * (end - start) + start
          );
        }
        if (target === "time") {
          this.currentDCTime = Math.floor(progress * (end - start) + start);
        }
        if (target === "rangePlus") {
          this.currentDCRangePlus = Math.floor(
            progress * (end - start) + start
          );
        }
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
    animateACValue(target, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        if (target === "battery") {
          this.currentACBatteryPercentage = Math.floor(
            progress * (end - start) + start
          );
        }
        if (target === "time") {
          this.currentACTime = Math.floor(progress * (end - start) + start);
        }
        if (target === "rangePlus") {
          this.currentACRangePlus = Math.floor(
            progress * (end - start) + start
          );
        }
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
    initDCPercentage() {
      if (this.currentCarInfo.id !== "VW-idbuzz") {
        this.animateDCValue("battery", 10, 80, 3000);
        this.animateDCValue(
          "time",
          0,
          Number(this.currentCarInfo.tenToEightyPercent) * 60,
          3000
        );
        this.animateDCValue(
          "rangePlus",
          0,
          Number(this.currentCarInfo.dcRangePlus),
          3000
        );
      } else {
        this.animateDCValue("battery", 5, 80, 3000);
        this.animateDCValue(
          "time",
          0,
          Number(this.currentCarInfo.tenToEightyPercent) * 60,
          3000
        );
        this.animateDCValue(
          "rangePlus",
          0,
          Number(this.currentCarInfo.dcRangePlus),
          3000
        );
      }
    },
    initACPercentage() {
      if (this.currentCarInfo.id !== "VW-idbuzz") {
        this.animateACValue(
          "battery",
          10,
          10 + Number(this.currentCarInfo.acRangePlusPercent),
          3000
        );
        this.animateACValue(
          "time",
          0,
          Number(this.currentCarInfo.tenToEightyPercent) * 60,
          3000
        );
        this.animateACValue(
          "rangePlus",
          0,
          Number(this.currentCarInfo.acRangePlus),
          3000
        );
      } else {
        this.animateACValue(
          "battery",
          5,
          5 + Number(this.currentCarInfo.acRangePlusPercent),
          3000
        );
        this.animateACValue(
          "time",
          0,
          Number(this.currentCarInfo.tenToEightyPercent) * 60,
          3000
        );
        this.animateACValue(
          "rangePlus",
          0,
          Number(this.currentCarInfo.acRangePlus),
          3000
        );
      }
    },
    checkIsPageControllerShow(actionType) {
      const bodyEl = document.body;
      const eventAction = () => {
        if (bodyEl.scrollTop > 70 && this.isPageControllerShow !== true) {
          this.isPageControllerShow = true;
        } else if (
          bodyEl.scrollTop < 70 &&
          this.isPageControllerShow === true
        ) {
          this.isPageControllerShow = false;
        } else {
          return;
        }
      };
      if (actionType !== "destroy") {
        bodyEl.addEventListener("scroll", eventAction);
        return;
      } else {
        bodyEl.removeEventListener("scroll", eventAction);
      }
    },
    checkIsBatteryAnimateInit(actionType) {
      const bodyEl = document.body;
      if (actionType === "destroy") {
        bodyEl.removeEventListener("scroll", eventAction);
        this.initBatteryAnimate = false;
        return;
      }

      const eventAction = () => {
        if (this.isInViewport() && this.initBatteryAnimate !== true) {
          this.initBatteryAnimate = true;
          this.initDCPercentage();
          this.initACPercentage();
        }
      };

      bodyEl.addEventListener("scroll", eventAction);
    },
    isInViewport() {
      // const rect = element.getBoundingClientRect();
      // const descBoxRect = document
      //   .getElementsByClassName("description-box")[0]
      //   .getBoundingClientRect();
      // console.log(document.getElementById("descBox").getBoundingClientRect());
      const descBox = document.getElementById("descBox");
      let descBoxRect;
      if (descBox) {
        descBoxRect = descBox.getBoundingClientRect();
      } else {
        return false;
      }

      return (
        descBoxRect.top >= 0 &&
        descBoxRect.left >= 0 &&
        descBoxRect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        descBoxRect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    scrollToTop() {
      document.body.scrollTop = 0;
    },
    setActiveItem(activeItem) {
      switch (activeItem) {
        case "充電曲線": {
          this.activeItem = 1;
          break;
        }
        case "下載APP": {
          this.redirectToAppPage();
          break;
        }
        case "所有車款": {
          this.$router.push({
            name: "CarsIntroduction",
          });
          break;
        }
        default: {
          this.$router.push({
            name: "CarsIntroduction",
          });
        }
      }
    },
    redirectToAppPage() {
      const googlePlayUrl =
        "https://play.google.com/store/apps/details?id=com.u_power";
      const appStoreUrl = "https://apps.apple.com/tw/app/u-power/id1612379206";

      if (this.userAgent === "Android") {
        window.location.href = googlePlayUrl;
        return;
      }
      if (this.userAgent === "IOS") {
        window.location = appStoreUrl;
        return;
      }
      if (this.userAgent === "WindowsDesktop") {
        window.open(
          googlePlayUrl,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes"
        );
        return;
      }
      if (this.userAgent === "MacDesktop") {
        window.open(
          appStoreUrl,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes"
        );
        return;
      }
    },
    checkDeviceOs() {
      let userAgent = navigator.userAgent;

      if (userAgent.match(/Android/i)) {
        if (userAgent.match(/Chrome/i)) {
          // Android + Chrome
          setTimeout(() => {
            if (!document.webkitHidden) {
              this.userAgent = "Android";
              return;
            }
          }, 1000);
        } else {
          // Android + 非 Chrome
          this.userAgent = "Android";
          return;
        }
      } else if (userAgent.match(/(iPhone|iPad|iPod)/i)) {
        setTimeout(() => {
          this.userAgent = "IOS";
          return;
        }, 25);
      } else if (userAgent.match(/(Macintosh|Mac)/i)) {
        // Mac Desktop Device
        setTimeout(() => {
          this.userAgent = "MacDesktop";
          return;
        }, 25);
      } else if (userAgent.match(/(Windows)/i)) {
        // Windows Desktop Device
        setTimeout(() => {
          this.userAgent = "WindowsDesktop";
          return;
        }, 25);
      } else {
        //其他
        let loadDateTime = new Date();
        setTimeout(() => {
          let timeOutDateTime;
          timeOutDateTime = new Date();
          if (timeOutDateTime - loadDateTime < 5000) {
            this.userAgent = "Android";
            return;
          }
        }, 1000);
      }
    },
  },
  watch: {
    currentDCTime() {
      const seconds = this.currentDCTime;
      const duration = dayjs.duration(seconds, "seconds");
      const minutes = duration.minutes(); // 獲取分鐘數
      const formattedTime = dayjs
        .utc(duration.asMilliseconds())
        .format(minutes >= 10 ? "mm:ss" : "mm:ss");
      this.currentDCTimeFinal = formattedTime;
    },
    currentACTime() {
      const seconds = this.currentACTime;
      const duration = dayjs.duration(seconds, "seconds");
      const minutes = duration.minutes(); // 獲取分鐘數
      const formattedTime = dayjs
        .utc(duration.asMilliseconds())
        .format(minutes >= 10 ? "mm:ss" : "mm:ss");
      this.currentACTimeFinal = formattedTime;
    },
  },
};
</script>

<template>
  <div id="chargingDetail-wrapper" v-if="currentCarInfo">
    <div class="main-content">
      <div class="title">{{ currentCarInfo.car }}</div>
      <div class="subtitle">{{ currentCarInfo.model }}</div>
      <div class="cover-box">
        <img
          :src="require('../../assets/images/' + currentCarInfo.id + '.jpg')"
          alt="thumb"
        />
      </div>
      <div class="spec-box" v-if="activeItem === 1">
        <div>
          <span>{{ currentCarInfo.capacity + " kWh" }}</span>
          <span>電池容量</span>
        </div>
        <div class="divider"></div>
        <div>
          <span>{{ currentCarInfo.dcMaxInput + " kW" }}</span>
          <span>充電速度</span>
        </div>
        <div class="divider"></div>
        <div>
          <span>{{ currentCarInfo.tenToEightyPercent + "分鐘" }}</span>
          <span v-if="currentCarInfo.id !== 'VW-idbuzz'">10~80% 充電</span>
          <span v-else>5~80% 充電</span>
        </div>
      </div>
      <div class="metrics-box" v-if="activeItem === 1">
        <div class="main-title">充電速度比較</div>
        <div class="batterys-box">
          <!-- DC電池 -->
          <div class="battery-box">
            <div class="title">超高速 360 kW</div>
            <div class="battery-box-bg">
              <img src="../../assets/images/battery-outline.png" alt="" />
            </div>
            <div class="battery-box-outer">
              <div
                class="battery-box-inner active"
                :class="{ show: initBatteryAnimate }"
              ></div>
            </div>
            <div class="battery-box-info">
              <div>
                <div class="battery-percent">
                  <span
                    v-if="
                      currentCarInfo.id === 'VW-idbuzz' && !initBatteryAnimate
                    "
                  >
                    5
                  </span>
                  <span v-else>
                    {{ currentDCBatteryPercentage }}
                  </span>
                  <span>%</span>
                </div>
              </div>
              <div class="range-text">
                <span>
                  {{ "+" + currentDCRangePlus }}
                  <small>km</small>
                </span>
                <span>（里程數）</span>
              </div>
            </div>
            <div class="time">{{ currentDCTimeFinal }}</div>
          </div>
          <div class="middle-item-box">
            <div class="title">VS</div>
            <div class="divider"></div>
            <div class="main-content">
              <div>DC<br />快充</div>
              <div class="icon">
                <img src="../../assets/images/flash-icon.png" alt="" />
              </div>
              <div>AC<br />慢充</div>
            </div>
            <div class="divider"></div>
          </div>
          <!-- AC電池 -->
          <div class="battery-box">
            <div class="title">慢充 7 kW</div>
            <div class="battery-box-bg">
              <img src="../../assets/images/battery-outline.png" alt="" />
            </div>
            <div class="battery-box-outer">
              <div
                class="battery-box-inner-ac active"
                :class="{ show: initBatteryAnimate }"
              ></div>
            </div>
            <div class="battery-box-info">
              <div>
                <div class="battery-percent">
                  <span
                    v-if="
                      currentCarInfo.id === 'VW-idbuzz' && !initBatteryAnimate
                    "
                  >
                    5
                  </span>
                  <span v-else>
                    {{ currentACBatteryPercentage }}
                  </span>
                  <span>%</span>
                </div>
              </div>
              <div class="range-text">
                <span>
                  {{ "+" + currentACRangePlus }}
                  <small>km</small>
                </span>
                <span>（里程數）</span>
              </div>
            </div>
            <div class="time">{{ currentACTimeFinal }}</div>
          </div>
        </div>
        <div id="descBox" class="description-box">
          <ul>
            <li>{{ "續航里程數據參考" + currentCarInfo.rangeSrc }}</li>
            <li>{{ currentCarInfo.description }}</li>
          </ul>
        </div>
      </div>
      <div class="spec-box">
        <ul>
          <li>
            <span>車身尺碼</span>
            <span>{{ currentCarInfo.size }}</span>
          </li>
          <li>
            <span>車重</span>
            <span>{{ currentCarInfo.weight }}</span>
          </li>
          <li>
            <span>電池容量</span>
            <span>{{ currentCarInfo.capacity + " kWh" }}</span>
          </li>
          <li>
            <span>續航里程</span>
            <span>
              <span>{{
                currentCarInfo.range +
                "公里(參考" +
                currentCarInfo.rangeSrc +
                ")"
              }}</span>
              <br />
              <span
                v-if="currentCarInfo.rangeWLTP"
                style="display: block; margin-top: 0.5rem"
                >{{ currentCarInfo.rangeWLTP + "公里(參考WLTP)" }}</span
              >
            </span>
          </li>
          <li>
            <span>最大馬力</span>
            <span>{{ currentCarInfo.hp }}</span>
          </li>
          <li>
            <span>0-100 km/h</span>
            <span>{{ currentCarInfo.zeroOneHundred }}</span>
          </li>
          <li>
            <span>極速</span>
            <span>{{ currentCarInfo.maxSpeed }}</span>
          </li>
          <li>
            <span>傳動</span>
            <span>{{ currentCarInfo.transmission }}</span>
          </li>
          <li>
            <span>電力系統</span>
            <span>{{ currentCarInfo.electricalSystem + "V" }}</span>
          </li>
          <li>
            <span>AC充電規格</span>
            <span>{{ currentCarInfo.acSpec }}</span>
          </li>
          <li>
            <span>DC充電規格</span>
            <span>{{ currentCarInfo.dcSpec }}</span>
          </li>
          <li>
            <span>DC最高功率</span>
            <span v-if="currentCarInfo.id !== 'VW-idbuzz'">{{
              currentCarInfo.dcMaxInput +
              " kW 10% ~ 80% " +
              currentCarInfo.tenToEightyPercent +
              "分鐘"
            }}</span>
            <span v-else>{{
              currentCarInfo.dcMaxInput +
              " kW 5% ~ 80% " +
              currentCarInfo.tenToEightyPercent +
              "分鐘"
            }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="page-controller-container"
      :class="{ show: isPageControllerShow }"
    >
      <div class="page-controller-box">
        <div
          class="page-controller-item"
          :class="{ active: activeItem === 1 }"
          @click="setActiveItem('充電曲線')"
        >
          充電曲線
        </div>
        <div class="page-controller-item" @click="setActiveItem('所有車款')">
          所有車款
        </div>
        <div class="page-controller-item" @click="setActiveItem('下載APP')">
          下載 APP
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#chargingDetail-wrapper {
  position: relative;
  margin: auto;
  padding: 1.5rem;
  padding-bottom: 5rem;
  width: 100%;
  max-width: 430px;
  .main-content {
    text-align: center;
    .title {
      font-size: 24px;
      font-weight: 500;
      color: black;
    }
    .subtitle {
      font-size: 15px;
      color: hsla(0, 0%, 53%, 1);
      margin: 0.5rem 0 1rem 0;
    }
    .cover-box {
      position: relative;
      width: auto;
      max-width: 430px;
      margin: auto;
      border-radius: 1rem;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        max-width: 430px;
      }
    }
    .spec-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 0;
      div:not(.divider) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // flex-grow: 1;
        min-width: 33%;
        gap: 0.5rem;
        span:nth-child(1) {
          font-size: 20px;
          font-weight: 500;
          color: black;
        }
        span:nth-child(2) {
          font-size: 12px;
          color: hsla(0, 0%, 53%, 1);
        }
      }
      .divider {
        width: 1px;
        min-width: 1px;
        height: 24px;
        background: hsla(0, 0%, 53%, 1);
      }
    }
    .metrics-box {
      padding: 1.5rem 1rem;
      border-radius: 1rem;
      background: white;
      .main-title {
        margin-bottom: 2rem;
        font-size: 18px;
        font-weight: 700;
      }
      .batterys-box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        .battery-box {
          position: relative;
          width: 74px;
          height: 170px;
          margin: 3rem 0;
          .title {
            position: absolute;
            top: -50px;
            left: 50%;
            width: 110px;
            font-size: 15px;
            font-weight: 500;
            transform: translateX(-50%);
          }
          .battery-box-bg {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 120%;
            height: 115%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 0;
            img {
              display: block;
              width: 100%;
              height: 100%;
              transform: translateY(-2.5%);
            }
          }
          .battery-box-outer {
            position: relative;
            width: 100%;
            height: 100%;
            background-color: #333;
            border-radius: 0.5rem;
            overflow: hidden;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            .battery-box-inner {
              position: relative;
              width: 100%;
              height: 20%;
              &-ac {
                position: relative;
                width: 100%;
                height: 20%;
              }
              &.active {
                background-image: url("../../assets/images/wave1.png"),
                  url("../../assets/images/wave2.png");
                background-repeat: repeat-x;
                background-size: 160px;
                background-position: 0 0px, 0 0px;
                animation: 20s waves linear infinite forwards;
                &.show {
                  animation: 20s waves linear infinite forwards,
                    3s charging linear forwards;
                }
              }
            }
            .battery-box-inner-ac.active {
              background-image: url("../../assets/images/wave1.png"),
                url("../../assets/images/wave2.png");
              background-repeat: repeat-x;
              background-size: 160px;
              background-position: 0 0px, 0 0px;
              animation: 20s waves linear infinite forwards;
              &.show {
                animation: 20s waves linear infinite forwards,
                  3s chargingAC linear forwards;
              }
            }

            @keyframes waves {
              to {
                background-position: 800px 0px, 1280px 0px;
              }
            }
            @keyframes charging {
              0% {
                height: 15%;
              }
              90% {
                height: 82%;
              }
              91% {
                height: 81%;
              }
              92% {
                height: 80%;
              }
              93% {
                height: 79%;
              }
              94% {
                height: 78%;
              }
              95% {
                height: 79%;
              }
              96% {
                height: 80%;
              }
              97% {
                height: 81%;
              }
              98% {
                height: 80%;
              }
              99% {
                height: 79%;
              }
              100% {
                height: 80%;
              }
            }
            @keyframes chargingAC {
              0% {
                height: 15%;
              }
              90% {
                height: 25%;
              }
              91% {
                height: 26%;
              }
              92% {
                height: 25%;
              }
              93% {
                height: 24%;
              }
              94% {
                height: 23%;
              }
              95% {
                height: 24%;
              }
              96% {
                height: 25%;
              }
              97% {
                height: 26%;
              }
              98% {
                height: 25%;
              }
              99% {
                height: 24%;
              }
              100% {
                height: 25%;
              }
            }
          }
          .battery-box-info {
            position: absolute;
            left: 50%;
            top: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 0.25rem;
            width: 100%;
            height: 100%;
            color: #fff;
            transform: translate(-50%, -50%);
            .battery-percent {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              gap: 0.25rem;
              span:nth-child(1) {
                font-size: 30px;
                font-weight: 500;
              }
              span:nth-child(2) {
                font-size: 15px;
                transform: translateY(-3px);
              }
            }
            .range-text {
              color: #ffc200;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              gap: 0.25rem;
              span:nth-child(1) {
                font-size: 18px;

                small {
                  font-size: 12px;
                }
              }
              span:nth-child(2) {
                font-size: 12px;
              }
            }
          }
          .time {
            position: absolute;
            bottom: -30px;
            left: 50%;
            width: 140px;
            font-size: 13px;
            font-weight: 500;
            transform: translateX(-50%);
          }
        }
      }
      .middle-item-box {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 1.5rem;
        .title {
          position: absolute;
          top: -55px;
          left: 50%;
          width: 110px;
          font-size: 15px;
          font-weight: 500;
          transform: translateX(-50%);
        }
        .divider {
          height: 40px;
          width: 1px;
          background: #ddd;
        }
        .main-content {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          font-size: 11px;
          font-weight: 500;
          img {
            width: 32px;
            height: auto;
          }
        }
      }
      .description-box {
        li {
          margin-left: 0.75rem;
          list-style-type: disc;
          text-align: left;
          font-size: 10px;
          color: #888;
          font-weight: 500;
          line-height: 14px;
        }
      }
    }
    .spec-box {
      ul {
        width: 100%;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem 0;
          border-bottom: 1px solid #ddd;
          font-size: 15px;
          text-align: left;
          span:nth-child(1) {
            width: 100px;
          }
          span:nth-child(2) {
            flex-grow: 1;
          }
        }
      }
    }
  }
  .page-controller-container {
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 430px;
    height: auto;
    padding: 1.5rem;
    transform: translate(-50%, 100%);
    transition: 0.2s all ease-in-out;
    &.show {
      transform: translate(-50%, 00%);
    }
    .page-controller-box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.75rem;
      background: rgba(0, 125, 134, 0.8);
      backdrop-filter: blur(2px);
      border-radius: 2rem;
      .page-controller-item {
        text-align: center;
        flex-grow: 1;
        padding: 1rem 0;
        border-radius: 2rem;
        font-size: 14px;
        color: $white;
        &.active,
        &:hover {
          background: $warning;
          color: $primary;
        }
      }
    }
  }
}
</style>
